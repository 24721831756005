import { Slider, SliderValueLabelProps, Tooltip, Typography } from "@mui/material";
import React from 'react';

type WeatherAltitudeSliderProps = {
    setUpdateWeatherInfo: React.Dispatch<React.SetStateAction<boolean>>
    displayAltitude: number
    setDisplayAltitude: React.Dispatch<React.SetStateAction<number>>
};

function ValueLabelComponent(props: SliderValueLabelProps) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="right" title={<Typography variant="subtitle2">{value}</Typography>}>
            {children}
        </Tooltip>
    );
};

export const WeatherAltitudeSlider: React.FC<WeatherAltitudeSliderProps> = (props) => {

    const handleAltitudeSliderChangeCommited = (newValue: number | number[]) => {
        props.setDisplayAltitude(newValue as number);
        props.setUpdateWeatherInfo(true);
    };

    const handleAltitudeSliderChange = (newValue: number | number[]) => {
        props.setDisplayAltitude(newValue as number);
    };

    return (
        <Slider
            size='small'
            orientation="vertical"
            value={props.displayAltitude}
            marks
            step={10}
            valueLabelDisplay="auto"
            max={300}
            valueLabelFormat={(newValue) => `${newValue}m`}
            onChange={(event, newValue) => handleAltitudeSliderChange(newValue)}
            onChangeCommitted={(event, newValue) => handleAltitudeSliderChangeCommited(newValue)}
            slots={{
                valueLabel: ValueLabelComponent,
            }}
            sx={{ height: `calc(100vh - 250px)` }}
        />
    )
}