/**
 * 気象要素
 */
export const MeteorologicalElement = {
    // 東西風成分(m/s)
    Ugrd: "ugrd",
    // 南北風成分(m/s)
    Vgrd: "vgrd",
    // 気温(K)
    Tmp: "tmp",
    // 降水量(mm)
    Apcp: "apcp",
    // 下層雲量(%)
    Lcdc: "lcdc"
} as const;
export type MeteorologicalElement = typeof MeteorologicalElement[keyof typeof MeteorologicalElement];

/**
 * index-APIのレスポンスに入る気象要素
 */
export const MeteorologicalElementForResponse = {
    // 東西風成分(m/s)
    UGRD: "UGRD",
    // 南北風成分(m/s)
    VGRD: "VGRD",
    // 気温(K)
    TMP: "TMP",
    // 降水量(mm)
    APCP: "APCP",
    // 下層雲量(%)
    LCDC: "LCDC"
} as const;
export type MeteorologicalElementForResponse = typeof MeteorologicalElementForResponse[keyof typeof MeteorologicalElementForResponse];

/**
 * index-APIのレスポンス
 */
export interface WeathernewsIndexAPIResponse {
    Index: {
        // 気象要素
        Element: MeteorologicalElementForResponse,
        // 処理結果
        Message: string,
        // タイムリスト
        Timelist: {
            // 気象予測情報発表時間UTC
            Basetime: string,
            // 気象予測対象時間UTC
            Validtime: string[]
        }[]
    }[];
};

/**
 * data-APIのリクエスト
 */
export interface WeathernewsDataAPIRequest {
    // 気象要素
    elements: MeteorologicalElement[];
    // 海面高度
    altitude?: string;
    // 地上高度
    alt_surface?: string;
    // 気象予測発表時間UTC
    basetime: string;
    // 気象予測対象時間UTC
    validtime: string;
    // 北西端緯度
    lat_start: string;
    // 北西端経度
    lon_start: string;
    // 南東端緯度
    lat_end: string;
    // 南東端経度
    lon_end: string;
    // 予測対象時間からの指定した10分の時間
    minutes?: string;
    // メッシュサイズ
    grid?: string;
};

/**
 * data-APIのレスポンス
 */
export interface WeathernewsDataAPIResponse {
    Data: {
        // 予測データ数値
        Contents: number[],
        // リクエスト要求内容
        RequestParameters: {
            // 気象予測情報発表時間UTC
            Basetime: string,
            // 気象予測対象時間UTC
            Validtime: string,
            // 10分単位の指定時間
            Minute: string,
            // 気象要素
            Element: string,
            // 海面高度
            Altitude?: string,
            // 地上高度
            Alt_surface?: string,
            // 北西端緯度
            Lat_start: string,
            // 北西端経度
            Lon_start: string,
            // 南東端緯度
            Lat_end: string,
            // 南東端経度
            Lon_end: string,
            // メッシュサイズ
            Grid: string,
            // 緯度の間隔(固定値:-0.0025)
            Lat_interval: string,
            // 経度の間隔(固定値:0.003125)
            Lon_interval: string,
            // レスポンスデータの北西端緯度
            Lat_start_base: string,
            // レスポンスデータの北西端経度
            Lon_start_base: string,
            // レスポンスデータの南東端緯度
            Lat_end_base: string,
            // レスポンスデータの南東端経度
            Lon_end_base: string,
            // 縦メッシュ数 ※１メッシュ250m
            Height: string,
            // 横メッシュ数 ※１メッシュ250m
            Width: string
        };
    }[];
};

